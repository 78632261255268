import { Card, Grid, styled, useTheme, TextField, Button } from '@mui/material';
import { Fragment, useState } from 'react';
import Parse from "../vendor/Parse"
import { useDispatch } from 'react-redux';
import useAuth from '../hooks/userAuth';
import { Formik } from 'formik';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

const H4 = styled('h4')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginBottom: '16px',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

const Analytics = ({ location }) => {
  const { palette } = useTheme();
  const dispatch = useDispatch()
  const [password, setPassword] = useState("")
  const { getUser } = useAuth();
  const queryParams = new URLSearchParams(location.search)
  const e = queryParams.get("e")
  const o = queryParams.get("o")

  const handlePasswordChange = async () => {
    
    try {
      const user = getUser();
      if (user) {
        await Parse.Cloud.run('updatePassword', {
          password: password
        })

      } else {
        if (e && o) {
          const res = await Parse.Cloud.run('resetPassword', {
            otp: o,
            email: e,
            password: password
          })

        }
      }

    } catch (e) {
      console.log(e)

    }

  }
  return (
    <Grid item xs={12} lg={6}> 
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card sx={{ px: 3, py: 2, mb: 3 }}>
              <Title>Change Password</Title>
              <SubTitle>Last 30 days</SubTitle>
              <br />
              <br />
              <TextField
                autoFocus={true}
                type="password"
                name="firstName"
                label="Change Password"

                value={password}
                onChange={(e) => setPassword(e.target.value)}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <br />
              <br />
              <Button sx={{ ml: 2 }} variant="contained" color="primary" onClick={() => handlePasswordChange()}>
                Change Password
              </Button>
            </Card>


          </Grid>


        </Grid>
      </ContentBox>
    </Grid>
  );
};

export default Analytics;
